import { useGetRequest } from 'api/useGetRequest';
import { ChatControllerService, ChatWithRelations } from 'generated';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { readStoreChatMessage } from 'redux/chat/actions';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { returnUpdatedList } from 'utils/helpers';
import usePaginationWrapper from 'utils/usePaginationWrapper/usePaginationWrapper';

interface IProps {
  chatRoomId?: string;
  reverse?: boolean;
}

const useGetChats = ({ chatRoomId, reverse = false }: IProps) => {
  const dispatch = useDispatch();
  const coach = useAppSelector((state) => state.user?.currentUser);
  const { setPageable, limit, page, totalElements, totalPages, setPage } = usePaginationWrapper();

  const [chats, setChats] = useState<(ChatWithRelations & { frontendId?: string })[]>([]);

  const onAddNewChatCallback = useCallback((newChat) => {
    setChats((prev) => [newChat, ...prev]);
  }, []);

  const onIncomingChat = useCallback(
    (incomingChat) => {
      if (incomingChat.senderId === coach?.id) {
        const existingChatIndex = chats.findIndex(
          (chat) => chat.frontendId === incomingChat.frontendId,
        );
        if (existingChatIndex !== -1) {
          const updatedChat = { ...chats[existingChatIndex], ...incomingChat };
          setChats((prevChats) => {
            const newChats = [...prevChats];
            newChats[existingChatIndex] = updatedChat;
            return newChats;
          });
        }
      } else {
        setChats((prevChats) => [incomingChat, ...prevChats]);
      }
    },
    [chats, coach?.id],
  );

  const onReadMessage = (chat: ChatWithRelations) => {
    setChats((prev) => {
      const newList = returnUpdatedList(chat, prev, 'id');
      return newList;
    });
    dispatch(readStoreChatMessage(chat));
  };

  const { data, loading, refetch, isFetching, isLoading } = useGetRequest({
    service: ChatControllerService.chatControllerFind,
    payload: {
      filter: {
        where: { chatRoomId },
        limit,
      },
      page,
      reverse,
    },
    tag: 'ChatControllerService',
    enabled: !!chatRoomId,
    onSuccess: (data) => {
      if (data?.currentPage === 1) {
        setChats(data?.data);
        setPageable(data);
      } else {
        setChats((prevChats) => [...prevChats, ...data?.data]);
      }
    },
  });

  useEffect(() => {
    if (chatRoomId) {
      refetch({ throwOnError: false, cancelRefetch: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomId, page]);

  const isInitialLoading = loading && chats.length === 0;

  return {
    chats,
    setChats,
    loading,
    isLoading,
    isFetching,
    isInitialLoading,
    onAddNewChatCallback,
    totalElements,
    totalPages,
    setPage,
    page,
    onReadMessage,
    onIncomingChat,
  };
};

export default useGetChats;
