import Avatar from 'components/Avatar/Avatar';
import MuiDrawer from 'components/Drawer/MUIDrawer';
import EmptyState from 'components/EmptyState/EmptyState';
import { ChatRoom, ChatWithRelations } from 'generated';
import moment from 'moment';
import ActiveChatModal from 'pages/chatPage/modals/ActiveChatModal';
import { useState } from 'react';
import { purifyHtml, truncateString } from 'utils/html-purify';
import useBreakpointValue from 'utils/useBreakpointValue/useBreakpointValue';
import useDisclosure from 'utils/useDisclosure/useDisclosure';

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  unreadMessages: ChatWithRelations[];
}

const UnreadMessagesDrawer = ({ isOpen, onClose, unreadMessages }: Props) => {
  const width = useBreakpointValue({
    base: '80vw',
    md: '40vw',
    lg: '30vw',
    xl: '25vw',
  });

  const structuredMessages: Record<string, ChatWithRelations[]> = unreadMessages?.reduce((t, a) => {
    if (!t[a.chatRoomId]) t[a.chatRoomId] = [a];
    else
      t[a.chatRoomId] = [...t[a.chatRoomId], a].sort((i, j) =>
        moment(i.created_at).isBefore(moment(j.created_at)) ? 1 : -1,
      );
    return t;
  }, {});

  const [activeRoom, setActiveRoom] = useState<ChatRoom | undefined>(undefined);
  const handler = useDisclosure();

  return (
    <MuiDrawer isOpen={isOpen} onClose={() => onClose()} title="Unread Messages" width={width}>
      {unreadMessages?.length > 0 ? (
        <div className="overflow-y-scroll max-h-screen">
          {Object.keys(structuredMessages).map((roomId) => {
            const item = structuredMessages[roomId]?.[0];
            const count = structuredMessages[roomId].length || 0;
            const image =
              item.chatRoom?.type === 'group' ? item.chatRoom?.imageUrl : item.senderImage;
            return (
              <div
                onClick={() => {
                  setActiveRoom(item.chatRoom);
                  handler.onOpen();
                }}
                key={roomId}
                className="flex items-center w-full justify-between"
              >
                <div
                  style={{ transition: '.2s ease-in-out' }}
                  className="flex border-b p-4 space-x-3 cursor-pointer hover:bg-lightGray w-full"
                >
                  <div className="h-[50px] w-[50px]">
                    <Avatar src={image} />
                  </div>
                  <div>
                    <p className="text-sm font-medium text-darkBlue">
                      {item.chatRoom?.groupName || item.senderName}
                    </p>
                    <div
                      className={`text-xs truncate text-gray-400 w-max`}
                      dangerouslySetInnerHTML={{
                        __html: truncateString(
                          purifyHtml(!item.resourceUrl ? item.message : 'Sent an attachment'),
                          20,
                        ),
                      }}
                    />
                  </div>
                </div>
                {count > 0 && (
                  <div className="h-6 w-6 rounded-full bg-orange text-center flex justify-center items-center">
                    <p className="text-xs text-white">{count}</p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <EmptyState
            height="400px"
            fullText
            title="No unread messages"
            description="You have read all your messages."
          />
        </div>
      )}
      <ActiveChatModal
        chatRoom={activeRoom}
        isOpen={handler.isOpen}
        onClose={() => {
          setActiveRoom(undefined);
          handler.onClose();
        }}
      />
    </MuiDrawer>
  );
};

export default UnreadMessagesDrawer;
