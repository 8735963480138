import { useGetRequest } from 'api/useGetRequest';
import { ChatControllerService, ChatWithRelations } from 'generated';
import { MutableRefObject } from 'react';
import { useAppSelector } from 'redux/hooks/useAppSelector';

const useGetUnreadChats = ({ ref }: { ref: MutableRefObject<boolean> }) => {
  const user = useAppSelector((state) => state.user?.currentUser);

  const { data } = useGetRequest({
    service: ChatControllerService.chatControllerGetUnreadMessages,
    tag: 'ChatControllerService',
    payload: { id: user?.id, senderType: 'coach' },
    enabled: ref.current === false ? true : false,
    onSuccess: (data) => {
      ref.current = true;
    },
  });

  return { unreadMessages: data as unknown as ChatWithRelations[] };
};

export default useGetUnreadChats;
