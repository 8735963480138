import { ChatWithRelations } from 'generated';
import { memo } from 'react';

type Props = { onOpen: () => void; unreadMessages: ChatWithRelations[] };

const UnreadMessagesComponent = memo(({ onOpen, unreadMessages }: Props) => {
  return (
    <>
      {unreadMessages?.length < 0 ? (
        <></>
      ) : (
        <div
          className="flex cursor-pointer items-center"
          onClick={() => {
            onOpen();
          }}
        >
          <i className={`mDoc-messages-3 text-[20px]`}></i>
          <p className="text-sm text-orange">{unreadMessages?.length}</p>
        </div>
      )}
    </>
  );
});

export default UnreadMessagesComponent;
