import Avatar from 'components/Avatar/Avatar';
import ChangePasswordModal from 'components/ChangePasswordModal/ChangePasswordModal';
import useMenu from 'components/Menu/Menu';
import UnreadMessagesDrawer from 'components/UnreadMessagesDrawer/UnreadMessagesDrawer';
import UnreadNotificationsDrawer from 'components/UnreadNotificationsDrawer/UnreadNotificationsDrawer';
import { useGetNotifications } from 'general-hooks/notifications/useGetNotifications';
import localforage from 'localforage';
import { useDashboardComponent } from 'pages/DashboardComponent';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateAppChatState } from 'redux/chat/actions';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { updateAppUserState } from 'redux/user/actions';
import { useChatSocketContext } from 'utils/chatSocketProvider/ChatSocketProvider';
import { useFCMToken } from 'utils/firebase/pushNotification';
import useDisclosure from 'utils/useDisclosure/useDisclosure';
import useGetUnreadChats from 'utils/useGetUnreadChats';
import UnreadMessagesComponent from './component/UnreadMessagesComponent';

interface IProps {
  onBack?: () => void;
  pageTitle: string;
  icon?: string;
}

const DashboardHeader = ({ onBack, pageTitle, icon }: IProps) => {
  useFCMToken();
  useGetNotifications();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const coach = useAppSelector((state) => state.user.currentUser);
  const id = coach?.id;
  const { goOffline, goOnline } = useChatSocketContext();

  useEffect(() => {
    if (id) {
      goOnline();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const unreadNotificationCount =
    useAppSelector((state) => state.notification.unreadNotificationCount) || 0;

  const { Menu, toggleMenu } = useMenu();
  const { isOpen: isEditOpen, onClose: onEditClose, onOpen: onEditOpen } = useDisclosure();
  const { toggle } = useDashboardComponent();

  const unreadDrawerHandler = useDisclosure();
  const unreadNotificationDrawerHandler = useDisclosure();

  const logoutUser = async () => {
    goOffline();
    await localforage.setItem('accessToken', '');
    dispatch(
      updateAppChatState({
        onlineMembers: [],
        // unreadMessages: [],
      }),
    );
    dispatch(
      updateAppUserState({
        currentUser: undefined,
        accessToken: undefined,
      }),
    );
  };

  const menuItems = [
    {
      iconClass: 'icon-settings',
      title: 'Account Settings',
      onClick: () => {
        navigate('/settings');
      },
      theme: 'default',
    },
    // {
    //   iconClass: 'icon-user',
    //   onClick: () => {
    //     navigate('/profile');
    //   },
    //   title: 'My profile',
    // },
    // { iconClass: 'icon-lock', title: 'Change Password', onClick: onEditOpen },
    { iconClass: 'icon-logout', title: 'Logout', onClick: logoutUser, theme: 'red-500' },
  ];
  const ref = useRef<boolean>(false);
  const { unreadMessages } = useGetUnreadChats({ ref });

  return (
    <header className="h-[80px] ml-5 md:ml-0 flex items-center justify-between px-6 border-b border-gray-200 mb-8">
      <div className="flex items-center">
        {/* {onBack ? (
          <i onClick={() => onBack?.()} className="mr-4 mt-1 cursor-pointer rotate-90">
            <ExpandCircleDownOutlinedIcon />
          </i>
        ) : (
          <i onClick={toggle} className="mr-4 mt-1 cursor-pointer md:hidden">
            <MenuIcon />
          </i>
        )} */}
        <div className="flex items-center gap-2 text-primaryBlue">
          <i className={`${icon} text-lg`} />
          <h1 className="text-base font-medium">{pageTitle}</h1>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <div
          className="flex cursor-pointer items-center"
          onClick={() => {
            unreadNotificationDrawerHandler.onOpen();
          }}
        >
          <i className={`mDoc-notification-1 text-[20px]`}></i>
          <p className="text-sm text-orange">{unreadNotificationCount}</p>
        </div>
        <UnreadMessagesComponent
          onOpen={unreadDrawerHandler.onOpen}
          unreadMessages={unreadMessages}
        />

        <div onClick={toggleMenu} className="relative flex items-center space-x-2 cursor-pointer">
          <div className="h-8 w-8">
            <Avatar src={coach?.image} />
          </div>
          <div>
            <p className="hidden md:block text-sm">{coach?.name}</p>
            <p className="hidden md:block text-xs text-gray-400">{coach?.email}</p>
          </div>
          <i className="mDoc-arrow-down-1" />

          <Menu itemList={menuItems} />
        </div>
      </div>
      <ChangePasswordModal isOpen={isEditOpen} onClose={onEditClose} />
      <UnreadMessagesDrawer
        isOpen={unreadDrawerHandler.isOpen}
        onClose={unreadDrawerHandler.onClose}
        unreadMessages={unreadMessages}
      />
      <UnreadNotificationsDrawer
        isOpen={unreadNotificationDrawerHandler.isOpen}
        onClose={unreadNotificationDrawerHandler.onClose}
      />
    </header>
  );
};

export default DashboardHeader;
